<template>
  <div class="flex">
    <div class="left flex flex-center flex-wrap">
      <div class="phone-box">
        <el-image
            style="width: 100%; height: 100rem"
            :src="imgTop"
            :fit="'contain'"></el-image>
        <div class="menu">
          <div class="flex flex-align flex-center" style="width: 70rem;border-right: 1rem solid #eee">
            <el-image
                style="width: 42rem"
                :src="imgKeyBord"
                :fit="'contain'"></el-image>
          </div>
          <div class="flex flex1">
            <div v-for="(item,index) in data_config_show" class="flex1 one-box"
                 style="position: relative"
                 :class="(active_index.length === 1 && active_index[0] === index) ? 'active' : ''"
                 @click="menuConfigHandle(index)">
              <el-popover
                  placement="top"
                  width="170"
                  popper-class="tools-wechat-menu-pop"
                  v-model="pop_visible[index]"
                  trigger="manual">
                <div class="tools-wechat-menu-two-box">
                  <div v-if="item.sub_button"
                       v-for="(_item,_index) in item.sub_button"
                       :class="(active_index.length === 2 && active_index[1] === _index) ? 'active' : ''"
                       style="position: relative"
                       class="tools-wechat-menu-two-box-cell">
                    <p class="text-one" @click="menuConfigHandleTwo(index, _index)">{{ _item.name }}</p>
                    <span class="span"
                          @click="deleteHandle('two',index,_index)"
                          style="position: absolute;top: 0;right: 4rem"><i class="el-icon-close"></i></span>
                  </div>
                  <div v-show="item.sub_button && item.sub_button.length < 5" class="flex flex-center flex1"
                       style="cursor:pointer;height: 40rem"
                       @click="twoMenuAdd(index)">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
                <p slot="reference">{{ item.name }}</p>
              </el-popover>
              <span class="span"
                    v-if="item.sub_button === undefined || !item.sub_button.length"
                    @click="deleteHandle('one',index)"
                    style="position: absolute;bottom: 5rem;left: 37rem"><i class="el-icon-close"></i></span>
            </div>
            <div v-show="data_config_show.length < 3" class="flex flex-center flex1"
                 style="cursor:pointer;"
                 @click="oneMenuAdd">
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="delete-box">-->
      <!--        <el-tooltip :disabled="!disabled" class="item" effect="dark" content="删除一级菜单时，需要先删除该菜单下所有子菜单"-->
      <!--                    placement="bottom">-->
      <!--          <el-button class="box" v-show="active_index.length" :disabled="disabled" @click="deleteHandle">-->
      <!--            <i class="el-icon-delete"></i>-->
      <!--          </el-button>-->
      <!--        </el-tooltip>-->
      <!--      </div>-->
    </div>
    <div class="right">
      <div>
        <h4>菜单信息</h4>
        <el-form v-show="active_index.length" ref="form" :model="form" label-width="80rem" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input v-model.trim="form.name"
                      maxlength="16"
                      @input="formToData"
                      placeholder="请输入名称标题"></el-input>
            <i class="notice">仅支持中英文和数字，字数不超过4个汉字或8个字母</i>
          </el-form-item>
          <template
              v-if="active_index.length === 2 || (active_index.length === 1 && !is_sub_btn)">
            <el-form-item label="消息类型" prop="type">
              <el-radio v-model="form.type"
                        @input="formToData"
                        v-for="item in type_config"
                        :label="item.value">
                {{ item.label }}
              </el-radio>
            </el-form-item>
            <template v-if="form.type === 'click'">
              <el-form-item label="事件名称" prop="key">
                <el-input v-model="form.key" placeholder="请输入" @input="formToData"></el-input>
              </el-form-item>
              <el-form-item label="消息内容" prop="remark">
                <el-input v-model="form.remark"
                          :rows="6"
                          placeholder="请输入"
                          @input="formToData"
                          type="textarea"></el-input>
              </el-form-item>
            </template>
            <template v-if="form.type === 'view'">
              <div class="eee">
                <el-form-item label="网页地址" prop="url">
                  <el-input v-model="form.url" @input="formToData" placeholder="请输入" style="width: 500rem;"></el-input>
                </el-form-item>
              </div>
            </template>
          </template>
        </el-form>
      </div>
      <div class="e-btn">
        <el-button type="primary" @click="onSubmit" :disabled="!this.active_index.length">保存</el-button>
      </div>
      <div class="msg" v-if="!!message">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import imgTop from '@/assets/img/wechat_menu_top.png'
import imgKeyBord from '@/assets/img/wechat_menu_keybord.png'

export default {
	_config:{"route":{"path":"index","meta":{"title":"操作"}}},
  data() {
    return {
      imgTop,
      imgKeyBord,
      active_index: [],  //当前被激活的模块，可以是一级菜单，也可以是二级菜单  例：[1]或[1,2]

      type_config: [
        {label: '发送消息', value: 'click'},
        {label: '跳转网页', value: 'view'},
      ],

      data_config: [],  //数据源
      data_config_show: [],  //用于渲染列表的数据
      data_config_old: [],  //用于撤销操作的数据复刻
      data_config_new: [],  //用于撤销操作前编辑的数据复刻

      is_sub_btn: false,

      form: {
        name: '',
        type: 'click',
        key: '',
        remark: '',
        url: ''
      },
      rules: {
        // name: [{required: true, message: '请输入名称标题', trigger: 'change'}],
        // key: [{required: true, message: '请输入事件名称', trigger: 'change'}],
        // url: [{required: true, message: '请输入网页地址', trigger: 'change'}],
      },

      message: ''
    }
  },
  computed: {
    disabled: function () {
      return this.active_index.length === 1 ? !!this.data_config_show[this.active_index[0]].sub_button.length : false
    },
    pop_visible: function () {
      let arr = [false, false, false]
      if (this.active_index.length) {
        arr[this.active_index[0]] = true
      }
      return arr
    }
  },
  watch: {
    data_config_show: {
      handler() {
        setTimeout(() => {
          if (this.active_index.length) {
            if (this.active_index.length === 1) {
              this.form.name = this.data_config_show[this.active_index[0]].name
            } else {
              let obj = this.data_config_show[this.active_index[0]].sub_button[this.active_index[1]]
              this.form.name = obj.name
              this.form.remark = obj.remark
              this.form.type = obj.type
              if (obj.type === 'click') {
                this.form.key = obj.key
              } else {
                this.form.url = obj.url
              }
            }
          }
        }, 200)
      },
      deep: true
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios3.post('api/wx/mp/get_current_selfmenu_info').then(res => {
        this.data_config = JSON.parse(JSON.stringify(res.data.data))
        let data = res.data.data.selfmenu_info.button
        console.log(data)
        this.data_config_show = data
        this.data_config_old = JSON.parse(JSON.stringify(data))
      })
    },

    oneMenuAdd() {
      let obj = {
        name: '菜单名字',
        type: 'click',
        key: '',
        sub_button: []
      }
      this.$set(this.data_config_show, this.data_config_show.length, obj)
      this.$nextTick(() => {
        if (this.data_config_show.length === 3 && !this.active_index.length) {
          this.active_index = [2]
        }
      })
    },

    twoMenuAdd(index) {
      this.data_config_show[index].sub_button.push({
        name: '菜单名字',
        type: 'click',
        key: '',
        remark: ''
      })
      this.$forceUpdate()
      if (this.data_config_show[index].sub_button.length < 5) {
        let length = this.data_config_show.length - 1
        let top = document.getElementsByClassName('tools-wechat-menu-pop')[length].style.top.slice(0, -2);
        document.getElementsByClassName('tools-wechat-menu-pop')[length].style.top = top - 40 + 'rem'
      }
    },

    resetForm() {
      this.form = {
        name: '',
        type: 'click',
        key: '',
        remark: '',
        url: ''
      }
    },

    menuConfigHandle(index) {
      this.resetForm()
      this.active_index = [index]
      this.form.name = this.data_config_show[index].name
      if (!!this.data_config_show[index].key) {
        this.form.type = this.data_config_show[index].type
        this.form.key = this.data_config_show[index].key
        this.form.remark = this.data_config_show[index].remark
        this.is_sub_btn = false
      }else{
        this.is_sub_btn = true
      }
    },

    menuConfigHandleTwo(index, _index) {
      this.resetForm()
      this.active_index = [index, _index]
      let obj = this.data_config_show[index].sub_button[_index]
      this.form.name = obj.name
      this.form.remark = obj.remark
      this.form.type = obj.type
      if (obj.type === 'click') {
        this.form.key = obj.key
      } else {
        this.form.url = obj.url
      }
    },

    deleteHandle(type, index1, index2) {
      let active_index = this.active_index;
      if (type === 'one') {
        if (active_index[0] === index1) {
          this.resetForm()
          this.active_index = []
          // let c_index = index;
          // this.data_config_show.forEach((item, _index) => {
          //   if (c_index === _index) {
          //     let length = this.data_config_show.length - 1
          //     let count = item.sub_button.length
          //     if (count === 5) count = 4
          //     let top = Number(635 - count * 40)
          //     document.getElementsByClassName('tools-wechat-menu-pop')[length].style.top = top + 'rem'
          //   }
          // })
        }
        let obj = this.data_config_show.filter((_item, _index) => _index !== index1)
        this.data_config_show = obj
        if (obj.length === 0) this.active_index = []
        this.$forceUpdate()
      } else {
        this.data_config_show[index1].sub_button = this.data_config_show[index1].sub_button.filter((_item, _index) => _index !== index2)
        if (active_index[0] === index1 && active_index[1] === index2) {
          this.active_index = []
        }
        //动态修改pop偏移量
        if (this.data_config_show[index1].sub_button.length < 4) {
          let length = this.data_config_show.length - 1
          let top = document.getElementsByClassName('tools-wechat-menu-pop')[length].style.top.slice(0, -2);
          let num = Number(top) + 40
          document.getElementsByClassName('tools-wechat-menu-pop')[length].style.top = num + 'rem'
        }
        this.$forceUpdate()
      }
    },

    formToData() {
      if (this.active_index.length === 1) {
        this.data_config_show[this.active_index[0]].name = this.form.name
        if (this.form.type === 'click') {
          this.data_config_show[this.active_index[0]].key = this.form.key
          this.data_config_show[this.active_index[0]].type = 'click'
          this.data_config_show[this.active_index[0]].remark = this.form.remark
        } else {
          this.data_config_show[this.active_index[0]].url = this.form.url
          this.data_config_show[this.active_index[0]].type = 'url'
        }
      } else {
        let obj = {}
        obj.name = this.form.name
        obj.type = this.form.type
        if (obj.type === 'click') {
          obj.key = this.form.key
          obj.remark = this.form.remark
        } else {
          obj.url = this.form.url
        }
        this.data_config_show[this.active_index[0]].sub_button[this.active_index[1]] = obj
      }
    },

    onSubmit() {
      if (this.form.name === '菜单名字' || !this.form.name) {
        this.$message.error('请填写完整信息！')
        return;
      }
      if (this.active_index.length === 2 && this.form.type === 0 && (!this.form.key || !this.form.remark)) {
        this.$message.error('请填写完整信息！')
        return;
      }
      if (this.active_index.length === 2 && this.form.type === 1 && !this.form.url) {
        this.$message.error('请填写完整信息！')
        return;
      }

      let boo = false;
      let str = []
      let data_config_copy = this.data_config_show.map(item => {
        if (item.sub_button && item.sub_button.length) {
          item.sub_button.forEach((_item, _index) => {
            if (!_item.name || _item.name === '菜单名字' || (_item.type === 'click' && !_item.key) || (_item.type === 'view' && !_item.url)) {
              boo = true;
              str.push('‘’' + _item.name + '‘’')
            }
          })
          return {
            name: item.name,
            sub_button: item.sub_button
          }
        } else {
          if (!item.name || item.name === '菜单名字' || !item.key) {
            this.$message.error('请填写完整信息！')
            boo = true;
            return false
          } else {
            return {
              name: item.name,
              type: item.type,
              key: item.key,
              remark: item.remark,
            }
          }
        }
      })
      if (boo) {
        this.$message.error('请填写完整信息！')
        this.message = str.join('，') + '内容不完整！请检查后重试'
        return;
      }

      let obj = {
        button: data_config_copy
      }
      let data = new FormData();
      data.append('menu_data', JSON.stringify(obj))
      this.$_axios3.post('api/wx/mp/create', data).then(res => {
        if (res.data.status === 0) {
          this.$message.success({
            message: '修改成功！',
            onClose: () => {
              this.getData()
              this.resetForm()
              this.active_index = []
              this.message = ''
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
p {
  color: #666;
  text-align: center;
}

.notice {
  //display: block;
  font-size: 12rem;
  color: #999;
  padding-left: 80rem;
}

.left {
  width: 375rem;
  box-shadow: 6rem 0rem 19rem -12rem rgba(0, 0, 0, .5);
  position: relative;

  .phone-box {
    width: 90%;
    height: 520rem;
    border-radius: 10rem;
    border: 1rem solid #ddd;
    overflow: hidden;
    position: relative;
    //background: greenyellow;

    .menu {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70rem;
      border-top: 1rem solid #ddd;
      display: flex;

      p {
        line-height: 56rem;
        font-size: 14rem;
      }

      .one-box {
        border-right: 1rem solid #eee;
        cursor: pointer;
        position: relative;

        &:last-child {
          border-right-width: 0;
        }

        &.active:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 67rem;
          border: 1rem solid #1d2088;
          pointer-events: none;
        }

        &.active:nth-child(3):after {
          top: 0;
          left: 0;
          width: 86rem;
          height: 67rem;
          border: 1rem solid #1d2088;
          border-bottom-right-radius: 10rem;
        }
      }
    }
  }

  ::v-deep .p2 {
    line-height: 30rem;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .delete-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -125rem;

    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20rem;
      border-radius: 50%;
      width: 46rem;
      height: 46rem;
      border: 1rem solid #ccc;
      transition: .25s;
    }
  }


}

.right {
  flex: 1;
  padding: 20rem 40rem 0 40rem;
  position: relative;

  h4 {
    font-size: 18rem;
    margin-bottom: 30rem;
  }

  .e-btn {
    position: absolute;
    bottom: 100rem;
    left: 40rem;
  }

  .msg {
    position: absolute;
    bottom: 60rem;
    left: 40rem;
    font-size: 14rem;
    color: red;
  }
}

::v-deep .eee .el-input {
  width: 580rem !important;
}

::v-deep .el-popper .popper__arrow::after {
  bottom: 0 !important;
}
</style>
